import React from 'react';
import {Link, Navigate} from "react-router-dom";
import Alert from "@mui/material/Alert";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import {servicesBase} from "../common";


const User = ({ user, setUsers, setError, token }) => {

  const handleDelete = (userId) => {
    fetch(`${servicesBase()}auth/user/${userId}`,
      { method: "DELETE", headers: {token: token }})
      .then(() => {
        setUsers((users) => users.filter((user) => user.id !== userId));
      })
      .catch((e) => {
        setError(e.toString());
      });
  }

  return (
    <TableRow>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>
        <Link to={`/edit_user/${user.id}`}><EditIcon/></Link>
        <DeleteIcon onClick={() => handleDelete(user.id)}/>
      </TableCell>
    </TableRow>
  );
}

const UserEditList = ({ isAdmin, token }) => {
  const [ users, setUsers ] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const error = Boolean(errorMessage);
  const [infoMessage, setInfoMessage] = React.useState('');
  const info = Boolean(infoMessage);

  React.useEffect(() => {
    fetch(`${servicesBase()}auth/user`, { headers: { token: token }})
      .then((response) => response.json())
      .then((response) => {
        setUsers(response)
      })
      .catch(() => {
        setUsers(null);
      });
  }, []);

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h4">User List</Typography>
      <TableContainer component={Paper}>
        {!isAdmin ? <Navigate to={"/auth_error"}/> : null}
        <Table>
          <TableHead>
            <TableCell>Email</TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          <TableBody>
            {users.map((user) => <User key={user.id} user={user} setUsers={setUsers} setError={setErrorMessage} setInfoMessage={setInfoMessage} token={token}/> )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
      </Snackbar>
      <Snackbar open={info} message={infoMessage} severity="info" onClose={() => setInfoMessage(null)} autoHideDuration={6000}/>
    </Grid>
  );
}

export default UserEditList;