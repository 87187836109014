import React from 'react';
import { useNavigate } from "react-router-dom";
import AtBat from './brandon-mowinkel-3_JwPJwq6CI-unsplash.jpg';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";


const Hero = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', display: 'flex', minHeight: '100px', alignItems: 'center', justifyContent: 'center'}}>
      <Grid container spacing={6} sx={{ flexGrow: 1, padding: '10px', maxWidth: '1100px', margin: '30px auto'}}>
        <Grid item xs={12} sm={7}>
          <Typography variant="h3" sx={{paddingBottom: '15px'}}>Bring Your Fans Closer to the Action</Typography>
          <Typography variant="h6" sx={{opacity: '0.4', paddingBottom: '30px'}}>
            BaseballPeople helps fans share livestreamed and prerecorded video with fans, parents, grandparents, and scouts.</Typography>
          <Button onClick={() => navigate("/register")} variant="contained" color="primary" sx={{ width: '200px', fontSize: '16px'}}>Get Started</Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <img src={AtBat} alt="At Bat" width="100%"/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Hero;