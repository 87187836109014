import {FormContainer, TextFieldElement} from "react-hook-form-mui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from 'react';
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {servicesBase} from "./common";

const ForgotPassword = () => {
  const [infoMessage, setInfoMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const info = Boolean(infoMessage);
  const error = Boolean(errorMessage);

  const onSubmit = (formData) => {
    const data = new FormData();
    data.append("email", formData.email);

    fetch(`${servicesBase()}auth/reset_password`, {method: "POST", body: data})
      .then((response) => {
        if (response.status === 200) {
          setInfoMessage("An email has been sent to the address you provided. Please follow the instructions in the email to reset your password.");
        } else {
          setInfoMessage("An error occurred. Please try again later.");
        }
      })
      .catch((error) => {
        setErrorMessage(error.toString())
      });
  }

  return (
    <Box m={3}>
      <FormContainer onSuccess={onSubmit}>
        <Grid container spacing={3} my={1}>
          <Grid item xs={12}>
            <Typography variant="h4">Forgot Password</Typography>
            <Typography variant="body1">Enter your email address below and we will send you a link to reset your
              password.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={1} maxWidth="500px">
              <TextFieldElement name="email" autoFocus size="small" label="Email Address" fullWidth/>
              <Button type='submit' variant="contained" color="primary">Send</Button>
            </Stack>
          </Grid>
        </Grid>
      </FormContainer>
      <Snackbar open={info} message={infoMessage} severity="info" onClose={() => setInfoMessage(null)} autoHideDuration={6000}/>
      <Snackbar open={error} message={errorMessage} severity="error" onClose={() => setErrorMessage(null)} autoHideDuration={6000}/>
    </Box>
  )
}

export default ForgotPassword;