import React from 'react';
import Button from "@mui/material/Button";

const HoldToCaptureButton = ({bodyColor, startEvent, endEvent, children }) => {
  const [ nextState, setNextState ] = React.useState("none");

  const mouseDown = (e) => {
    e.preventDefault();
    if (nextState === "none") {
      setNextState("wait");
      setTimeout(() => {
        setNextState((liveState) => {
          return liveState === "wait" ? "endCapture" : liveState;
        });
      } , 250);
      startEvent();
    }
  }

  const mouseUp = (e) => {
    e.preventDefault();
    if (nextState === "endCapture") {
      setNextState("none");
      endEvent();
    } else if (nextState === "wait") {
      setNextState("endCapture");
    }
  }

  return (
    <Button variant="contained" color={bodyColor} onMouseDown={mouseDown} onMouseUp={mouseUp}>{children}</Button>
  );
}

export default HoldToCaptureButton;