import * as React from 'react';
import {parseJwt, servicesBase} from "./common";
import {useNavigate} from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MessageDialog from "./MessageDialog";
import SearchIcon from "@mui/icons-material/Search";
import Snackbar from '@mui/material/Snackbar';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const FindTeam = ({ token: token }) => {
  const [teams, setTeams] = React.useState([]);
  const [searchTermLongEnough, setSearchTermLongEnough] = React.useState(false);
  const [requestStatus, setRequestStatus] = React.useState({});
  let delayTimer;
  const user = parseJwt(token);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState('');
  const error = Boolean(errorMessage);
  const [notifyInvite, setNotifyInvite] = React.useState(false);

  React.useEffect(() => {
    fetch(`${servicesBase()}teams/user/${user.userId}/invite_status`, { headers: { token: token } })
      .then(response => response.json())
      .then(data => {
        const status = {};
        Object.entries(data).forEach((entry) => {
          const [teamId, message] = entry;
          status['team' + teamId] = message;
        });
        setRequestStatus(status);
      })
      .catch((error) => setErrorMessage(error.toString()));
  }, []);

  const requestInvite = (teamId) => {
    fetch(`${servicesBase()}teams/${teamId}/request_invite/${user.userId}`,
      { method: 'PUT', headers: { token: token } })
      .then(response => response.json())
      .then(data => {
        setRequestStatus((status) => {
          status['team' + teamId] = data.status;
          return status;
        });
        setNotifyInvite(true);
      })
      .catch(e => setErrorMessage(e.toString()));
  }

  const searchTeams = (e) => {
    const searchTerm = e.target.value;

    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      if (searchTerm.length >= 3) {
        setSearchTermLongEnough(true);
        fetch(`${servicesBase()}teams/search/${searchTerm}?user_id=${user.userId}`,
          { headers: { token: token } })
          .then(response => response.json())
          .then(data => setTeams(data))
          .catch((error) => setErrorMessage(error.toString()));
      } else {
        setTeams([]);
        setSearchTermLongEnough(false);
      }
    }, 1000);
  }

  return (
    <Box my={3}>
      <MessageDialog message={"Invite requested.  The Team Admin will review your request and notify you by email once you're approved"} open={notifyInvite} setOpen={setNotifyInvite} action={() => navigate("/")} />
      <TextField
        id="teamName"
        label="Search Team Name or Location (enter at least 3 characters)"
        onChange={searchTeams}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        fullWidth
      />
      <Grid container mt={2} spacing={2}>
        <>
          {teams.length === 0 && searchTermLongEnough ? <Grid item xs={12}><Typography>No teams found</Typography></Grid> : null}
          {!searchTermLongEnough ? <Grid item xs={12}><Typography>Enter a search terms to find teams</Typography></Grid> : null}
          {teams.map(team => (
            <>
              <Grid item xs={4}>
                <Typography variant={"h6"}>Team Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"h6"}>Location</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"h6"}>Status</Typography>
              </Grid>
              <Grid item key={team.id} xs={4}>
                <Typography>{team.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{team.location}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ position: 'relative' }}>
                {requestStatus['team' + team.id] ? (
                  <Typography>{requestStatus['team' + team.id]}</Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={() => requestInvite(team.id)} sx={{ position: 'absolute', top: '50%', transform: 'translateY(-40%)' }}>Request Invite</Button>
                )}
              </Grid>
            </>
          ))}
        </>
      </Grid>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
      </Snackbar>
    </Box>
  );
}

export default FindTeam;
