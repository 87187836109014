import * as React from "react";
import { Navigate } from "react-router-dom";
import AnnounceGame from "./AnnounceGame";
import Grid from "@mui/material/Grid";
import StreamingStatistics from "./StreamingStatistics";
import Teams from "./Teams";
import UserEditList from "./UserEditList";

const Admin = ({ isAdmin, token, live }) => {
  return (
    <Grid container spacing={2}>
      {!isAdmin ? <Navigate to={"/auth_error"} /> : null}
      <StreamingStatistics live={live} />
      <AnnounceGame isAdmin={isAdmin} token={token} live={live} />
      <UserEditList isAdmin={isAdmin} token={token} />
      <Teams token={token} />
    </Grid>
  );
};

export default Admin;
