import * as React from "react";
import {XMLParser} from "fast-xml-parser";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {getReadableSizeString} from "../common";
import sprintf from "sprintf";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";

const StreamingStatistics = ({live}) => {
  const [data, setData] = React.useState({});
  const parser = new XMLParser();
  const [liveStatsDate, setLiveStatsDate] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => setLiveStatsDate(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_HOME}admin/statistics`)
      .then((response) => response.text())
      .then((response) => {
        const xmlData = parser.parse(response);
        console.log(xmlData);

        let duration;
        if (xmlData.rtmp.server.application[1]?.live?.stream) {
          const total_seconds = xmlData.rtmp.server.application[1].live.stream.time / 1000;
          const hours = sprintf("%02d", total_seconds / 3600);
          const mins = sprintf("%02d", total_seconds / 60 % 60);
          const secs = sprintf("%02d", total_seconds % 60);
          duration = `${hours}:${mins}:${secs}`;
        } else {
          duration = "00:00:00";
        }

        setData({
            bytes_in: xmlData.rtmp.server.application[1].live?.stream?.bytes_in,
           bytes_out: xmlData.rtmp.server.application[1].live?.stream?.bytes_out,
        broadcasters: xmlData.rtmp.server.application[1].live?.nclients,
               width: xmlData.rtmp.server.application[1].live?.stream?.meta?.video?.width,
              height: xmlData.rtmp.server.application[1].live?.stream?.meta?.video?.height,
          frame_rate: xmlData.rtmp.server.application[1].live?.stream?.meta?.video?.frame_rate,
            duration: duration,
        });
      })
  }, [liveStatsDate]);

  let livevideo_info;
  if (data.height) {
    livevideo_info = (
      <>
        <Grid item xs={3}>
          <Typography>Resolution</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{data.width}x{data.height}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Frame rate</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{data.frame_rate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Bytes In</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{getReadableSizeString(data.bytes_in)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Bytes Out</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{getReadableSizeString(data.bytes_out)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Duration</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{data.duration}</Typography>
        </Grid>
      </>
    );
  } else {
    livevideo_info = null;
  }

  return (
    <Grid item xs={12} sm={6}>
      <Grid container>
        <Grid item xs={12} mb={2.5}>
          <Typography component="h1" variant="h5" sx={{mt: 5}}>
            Livestream Statistics
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Broadcasters</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{data.broadcasters}</Typography>
        </Grid>
        {livevideo_info ? livevideo_info : null}
      </Grid>
    </Grid>
  );
}

export default StreamingStatistics;