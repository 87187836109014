import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LiveVideoEntry from "./LiveVideoEntry";
import Video from "./Video";

const VideoArchive = ({selectVideo, videos, live, isLoading, isError, isAdmin, handleUpdate, token}) => {
    return (
      <Grid container spacing={2} role="list">
        {isLoading ? <Paper elevation={2}>Loading...</Paper> : null}
        {isError ? <Paper elevation={2}>Error...</Paper> : null}
        {live ? <LiveVideoEntry selectVideo={selectVideo} live={live}/> : null}
        {!isLoading && !isError ? <VideoList selectVideo={selectVideo} videos={videos} isAdmin={isAdmin} handleUpdate={handleUpdate} token={token}/> : null}
      </Grid>
    );
}

const VideoList = ({selectVideo, videos, isAdmin, handleUpdate, token}) => {
    return videos.map((video) =>
        <Video key={video.id} selectVideo={selectVideo} video={video} isAdmin={isAdmin} handleUpdate={handleUpdate} token={token}/>)
}

export default VideoArchive;
