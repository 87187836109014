import React, {useReducer} from 'react';
import {isAdmin, parseJwt, servicesBase} from "../common";
import {Link} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import ConfirmDialog from "../ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {CircularProgress} from "@mui/material";
import VideoJS from "../VideoJS";
import EditIcon from "@mui/icons-material/Edit";


const HighlightsReelTitle = () => {
    return (
        <h1>Highlights Reels</h1>
    );
}


const HighlightsReelInstructions = () => {
    return (
        <Box>
            Highlight combines highlights into a single video.  Click on the big plus below to
            create a new highlights reel.
        </Box>
    );
}

const ReelImage = ({ reel, setVideoVisible }) => {
  return (
    <>
      { !reel.processed ? <CircularProgress size={80} sx={{position: 'absolute', top: 60, left: 120}}/> : <PlayArrowIcon onClick={() => setVideoVisible(true)} sx={{fontSize: '72px', color: 'white', position: 'absolute', top: 70, left: 150}}/> }
      <img
        src={`${process.env.REACT_APP_SPACES_BASE}highlights/${reel.highlights[0].id}.png`}
        alt="Reel thumbnail"
        onClick={() => setVideoVisible(true)}
        style={{width: '100%', height: 'auto'}}
      />
    </>
  );
}

const ReelVideo = ({ reel }) => {
  const videoJSOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2],
    preload: 'auto',
    sources: [{src: `${process.env.REACT_APP_SPACES_BASE}reels/${reel.filename}`, type: 'video/mp4'}],
  }

  return (
    <>
      <VideoJS options={videoJSOptions} className="vjs-16-9"/>
    </>
  )
}

const ReelMedia = ({ reel }) => {
  const [videoVisible, setVideoVisible] = React.useState(false);

  return (
    <Grid item sx={{position: 'relative'}} xs={12}>
      { videoVisible ? <ReelVideo reel={reel}/> : <ReelImage reel={reel} setVideoVisible={setVideoVisible}/> }
    </Grid>
  )
}

const Reel = ({ reel, token }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const videoDateTime = dayjs(reel.created).fromNow();
  const downloadUrl = `${process.env.REACT_APP_SPACES_BASE}highlights/${reel.filename}`;

  function deleteMe() {
    fetch(`${servicesBase()}highlights/reel/${reel.id}`,
      {method: 'DELETE', headers: {token: token}})
      .then((response) => {
        window.location.reload();
      });
  }

  return (
    <>
      <ConfirmDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        action={deleteMe}
        message={"Are you sure you want to delete this reel?"}/>
      <Grid item m={2} xs={12} sm={6} md={4} lg={3} sx={{border: 'black solid 1px', textAlign: 'center'}}>
        <Grid container>
          <ReelMedia reel={reel}/>
          <Grid item xs={11}>
            <Typography component="h1" variant="h5">{reel.name}</Typography>
            <Typography sx={{fontSize: "12pt"}}>{reel.highlights.length} highlights</Typography>
            { reel.processed ? <Typography sx={{fontSize: "12pt"}}>Created {videoDateTime}</Typography> : null }
            { !reel.processed ? <Typography sx={{fontSize: "12pt"}}>Processing...</Typography> : null }
          </Grid>
          <Grid item xs={1}>
            <Stack spacing={0} mx={1}>
              <a href={`/edit_reel/${reel.id}`}><EditIcon/></a>
              <a href={downloadUrl} download><DownloadIcon sx={{color: 'black', fontSize: 20}}/></a>
              {isAdmin(token) ?
                <a onClick={() => setDialogOpen(true)}><DeleteIcon sx={{color: 'black', fontSize: 20}}/></a> : null}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}


const ReelList = ({ socket, token }) => {
    const [ reels, setReels ] = React.useState([]);
    const [updateNumber, handleUpdate] = useReducer(x => x+1, 0);

    React.useEffect(() => {
      if (!socket) return;

      socket.on('reel_processed', () => {
        handleUpdate();
      })
      return () => {
        socket.off('reel_processed');
      }
    }, [socket]);

  React.useEffect(() => {
        fetch(`${servicesBase()}highlights/reel?user_id=${parseJwt(token).userId}`, {
            method: 'GET',
            headers: {token: token}
        })
            .then(response => response.json())
            .then(data => {
                setReels(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [updateNumber]);

    return (
      <>
        <Grid container>
          <Grid item m={2} xs={12} sm={6} md={4} lg={3} sx={{border: 'black solid 1px', backgroundColor: '#999', textAlign: 'center'}}>
            <Link to="/add_highlights_reel">
              <AddIcon sx={{fontSize: "50pt", position: 'relative', height: '100%'}}/>
            </Link>
          </Grid>
          {reels.map((reel) => (<Reel key={reel.id} reel={reel} token={token}/>))}
        </Grid>
      </>
    );
}

const HighlightsReels = ({ playerRef, socket, token }) => {
    return (
      <>
        <HighlightsReelTitle/>
        <HighlightsReelInstructions/>
        <ReelList playerRef={playerRef} socket={socket} token={token}/>
      </>
    );
}

export default HighlightsReels;