import React from 'react';
import {useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {servicesBase} from "./common";


const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [message, setMessage] = React.useState('Unsubscribing...');

  React.useEffect(() => {
    const data = new FormData();
    data.append("email", email);
    data.append("token", token);

    fetch(`${servicesBase()}auth/unsubscribe`, { method: 'POST', body: data })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setMessage('Unsubscribed successfully.');
        } else {
          setMessage('Unsubscribe failed.  We apologize!  Please email us at support@baseballpeople.com and ' +
            'we will help you out ASAP!')
        }
      })
      .catch((e) => {
        setMessage('Unsubscribe failed.  We apologize!  Please email us at support@baseballpeople.com and ' +
          'we will help you out ASAP!');
      });
  });

  return (
    <Grid container spacing={2} maxWidth={500}>
      <Grid item xs={12}>
        <Typography variant="h6">Status: {message}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          You have requested to unsubscribe {email} from notifications from BaseballPeople. Once the status above
          says "Unsubscribed successfully", you will no longer receive any notifications from BaseballPeople.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          If you ever decide to turn notifications back on, click on your initials in the upper right corner of the
          screen and select "Profile" from the menu.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Unsubscribe;