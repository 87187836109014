import * as React from 'react';
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {parseJwt, servicesBase} from "../common";
import Stack from "@mui/material/Stack";

const EditReel = ({token}) => {
  const [reel, setReel] = React.useState(null);
  const [error, setError] = React.useState("");
  let {id} = useParams();
  const navigate = useNavigate();
  let {handleSubmit, register, reset} = useForm({
    defaultValues: {}
  });

  React.useEffect(() => {
    const user = parseJwt(token);
    fetch(`${servicesBase()}highlights/reel/${id}?user_id=${user.userId}`, {headers: {token: token}})
      .then((response) => response.json())
      .then((response) => {
        setReel(response);
      })
      .catch(() => {
        setError("Unable to load reel");
        setReel(null);
      });
  }, []);

  React.useEffect(() => {
    reset(reel);
  }, [reel]);

  const onSubmit = (inputData) => {
    const user = parseJwt(token);
    const data = new FormData();
    data.append("user_id", user.userId);
    data.append("name", inputData.name);
    data.append("filename", inputData.filename);

    fetch(`${servicesBase()}highlights/reel/${id}`, {method: "PUT", body: data, headers: {token: token}})
      .then((response) => {
        if (response.status === 200) {
          navigate("/highlights_reels");
        }
      })
      .catch((e) => {
        setError(e.toString())
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error ? <Typography sx={{color: 'red'}}>{error}</Typography> : null}
      <Container sx={{mt: 10}}>
        <CssBaseline/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography>Id</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              {...register('id', {required: true})}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              {...register('name', {required: true})}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography>Filename</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              {...register('filename')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} margin={2} spacing={2}>
              <Button type="submit" variant="contained" fullWidth>Save Changes</Button>
              <Button variant="contained" fullWidth onClick={() => navigate("/")}>Cancel</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
}

export default EditReel;