import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LiveVideoIcon from "@mui/icons-material/Videocam";
import Typography from "@mui/material/Typography";
import * as React from "react";

const LiveVideoEntry = ({selectVideo, live}) => {
  const selectLive = () => {
    selectVideo("live");
  };

  const videoDateTime = new Date(live.created).toLocaleString('en-US');

  return (
    <Grid item xs={12}>
      <Paper onClick={selectLive} elevation={5}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4} md={3} lg={2} onClick={selectLive}>
            <LiveVideoIcon sx={{width: 178, height: 100, color: '#D31111'}}/>
          </Grid>
          <Grid item sx={11} sm={7} md={8} lg={9} onClick={selectLive}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>Watch Live</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Title</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{live.title}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Started at</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{videoDateTime}</Typography>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default LiveVideoEntry;