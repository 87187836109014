import sprintf from "sprintf";
import {useCookies} from "react-cookie";
import * as React from "react";

export const parseJwt = (token) => {
    if (!token) {
        return {};
    }

    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getReadableSizeString(sizeInBytes) {
    let i = -1;
    let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        sizeInBytes /= 1024;
        i++;
    } while (sizeInBytes > 1024);

    return Math.max(sizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}


export const secondsToTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - (hours * 3600)) / 60);
    const seconds = Math.floor(time - (hours * 3600) - (minutes * 60));

    return sprintf("%02d:%02d:%02d", hours, minutes, seconds);
}

export const isAdmin = (token) => {
    const admin_test = /\badmin\b/;
    let parsedToken = parseJwt(token);
    return admin_test.test(parsedToken?.roles);
}

export const useCookieState = (key, initialState) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [value, setValue] = React.useState(cookies[key] || initialState);
    // Figure out which host to store cookies on
    let host = window.location.host;
    if (host.indexOf(":")) {
        host = host.split(":")[0];
    }
    if (host.endsWith(".baseballpeople.com")) {
        host = ".baseballpeople.com";
    } else if (host.endsWith(".baseballpeople.dev")) {
        host = ".baseballpeople.dev";
    }

    React.useEffect(() => {
        if (value === "" || value === null) {
            removeCookie(key, {domain: host});
        } else {
            let expires = new Date();
            expires.setFullYear(expires.getFullYear() + 1);
            setCookie(key, value, {domain: host, expires: expires});
        }
    }, [value, key]);

    return [value, setValue];
}

export const useStorageState = (key, initialState) => {
    const [value, setValue] = React.useState(
      localStorage.getItem(key) || initialState
    );

    React.useEffect(() => {
        localStorage.setItem(key, value);
    }, [value, key]);

    return [value, setValue];
}


export const baseDomain = () => {
    if (window.location.hostname.endsWith("com")) {
        return "baseballpeople.com";
    } else if (window.location.hostname.endsWith("baseballpeople.dev")) {
        return "baseballpeople.dev:3000";
    } else {
        return "localhost:3000";
    }
}


export const servicesBase = () => {
    let hostBase = "https://baseballpeople.com"
    if (window.location.hostname.endsWith(".baseballpeople.com")) {
        hostBase = "https://" + window.location.hostname;
    }
    return hostBase + "/services/";
}