import * as React from 'react';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

const ConfirmDialog = ({ message, open, setOpen, action }) => {

  const selectAndClose = (selected) => {
    if (selected) {
      action();
    }

    setOpen(false);
  }

  return (
    <Dialog onClose={action} open={open}>
      <DialogTitle>{message}</DialogTitle>
      <Container>
        <Stack direction={"row"} spacing={2} marginY={2} justifyContent="center">
          <Button variant="contained" data-cy="confirm-yes" onClick={() => selectAndClose(true)}>Yes</Button>
          <Button variant="contained" data-cy="confirm-no" onClick={() => selectAndClose(false)}>No</Button>
        </Stack>
      </Container>
    </Dialog>
  )
}

export default ConfirmDialog;

