import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {CheckboxElement} from "react-hook-form-mui";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {FormContainer, PasswordElement, PasswordRepeatElement, TextFieldElement} from "react-hook-form-mui";
import {servicesBase} from "./common";

const API_ENDPOINT = servicesBase() +"auth/";


const ErrorList = ({errors}) => {
  return (
    <Box>
      { errors.length > 0 ? <Typography sx={{color: 'red'}}>
        We found the following errors while registering you:
        <ul style={{ marginLeft: 30}}>
          {errors.map((error) => {
            return <li>{error}</li>
          })}
        </ul>
      </Typography> : null}
    </Box>
  )
}

const Register = ({setShowLogin}) => {
  const navigate = useNavigate();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [phoneVisible, setPhoneVisible] = React.useState(false);

  const onSubmit = (inputData) => {
    const data = new FormData();
    data.append("email", inputData.email);
    data.append("name", inputData.name);
    data.append("password", inputData.password);
    data.append("notify_by_email", inputData.notify_by_email);
    data.append("notify_by_sms", inputData.notify_by_sms);
    data.append("phone", inputData.phone);


    fetch(`${API_ENDPOINT}register`, { method: 'POST', body: data })
      .then((response) => response.json())
      .then((response) => {
        if (response.created) {
          navigate('/');
        } else {
          setServerErrors(response.errors);
        }
      });
  }

  return (
    <Container>
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <ErrorList errors={serverErrors}/>
        <FormContainer onSuccess={onSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldElement
                name="name"
                required
                fullWidth
                label={"Full Name"}
                autoFocus/>
            </Grid>
            <Grid item xs={12}>
              <TextFieldElement
                name="email"
                required
                fullWidth
                label={"Email Address"}
                autoComplete="email"/>
            </Grid>
            <Grid item xs={12}>
              <PasswordElement
                name="password"
                required
                fullWidth
                label="Password"
                type="password"
                autoComplete="new-password"/>
            </Grid>
            <Grid item xs={12}>
              <PasswordRepeatElement
                passwordFieldName="password"
                name={"password-repeat"}
                required
                fullWidth
                label="Verify Password"
                autoComplete="new-password"/>
            </Grid>
            <Grid item xs={12}>
              <Container>
              <CheckboxElement
                name="notify_by_email"
                label="Allow BaseballPeople to notify me by email"
              />
              <br/>
              <CheckboxElement
                name="notify_by_sms"
                label="Allow BaseballPeople to notify me by SMS"
                onClick={(e) => { setPhoneVisible(!phoneVisible) }}
              />
                {phoneVisible ? <TextFieldElement name={"phoneNumber"} label={"Phone Number"} size="small" type='tel' required={phoneVisible}/> : null}
              </Container>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt:3, mb: 2 }}>
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/" onClick={() => setShowLogin(true)}>Already have an account?  Sign in</Link>
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
    </Container>
  )

  // return (
  //   <form method="post" onSubmit={handleSubmit(onSubmit)}>
  //     <div className="controls">
  //       <input {...register("name")} type="text" placeholder="Enter Name"/><br/>
  //       <input {...register("email")} type="text" placeholder="Enter Email"/><br/>
  //       <input {...register("password")} type="password" placeholder="Enter password"/><br/>
  //       <input {...register("confirm_password")} type="password" placeholder="Enter password again"/><br/>
  //
  //       <button type="submit">Register</button>
  //     </div>
  //   </form>
  // )
}

export default Register;
