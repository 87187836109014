import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import logo from "./baseball.png";


const Footer = () => {
  return (
    <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', minHeight: '10vh', marginTop: '20px', padding: '10px', justifyContent: 'center', backgroundColor: 'primary.main', flexDirection: 'column'}}>
      <img src={logo} alt="BaseballPeople" style={{width: '50px', height: '50px', marginBottom: '10px'}}/>
      <Typography variant="caption" color="white">
        Copyright ©2023 BaseballPeople. All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default Footer;