import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import React from 'react';
import Typography from "@mui/material/Typography";
import {FormContainer, TextFieldElement } from "react-hook-form-mui";
import {useForm} from "react-hook-form";
import {servicesBase} from "../common";

const AnnounceGame = ({ token, live }) => {
  const [ message, setMessage ] = React.useState("");

  const handleSubmit = (inputData) => {
    const data = new FormData();
    data.append("message", inputData.message);

    fetch(`${servicesBase()}notify/all_by_sms`,{ method: 'POST', body: data, headers: { token: token } })
      .then((response) => response.json())
      .then((response) => {
        if (response.sent) {
          setMessage("Message sent");
        } else {
          setMessage("Message not sent");
        }
      })
      .catch((error) => {
        setMessage(error.message);
      });
  }

  const formContext = useForm({
    defaultValues: {
      message: `Game is streaming live now!  To watch the game live, go to https://baseballpeople.com?gameId=live.`
    }
  });

  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5">Announce Game</Typography>
        </Grid>
        { !live ? <Grid item xs={12}><Typography sx={{color: 'red'}}>Game is not streaming live</Typography></Grid> : null }
        { message ? <Grid item xs={12}><Typography>{message}</Typography></Grid> : null }
        <Grid item xs={12}>
          <FormContainer formContext={formContext} onSuccess={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldElement
                  name="message"
                  fullWidth
                  multiline
                  wrap="soft"
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Announce Game</Button>
              </Grid>
            </Grid>
          </FormContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AnnounceGame;