import React, { useEffect, useState } from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {servicesBase} from "./common";

const VerifyEmail = ({ setToken }) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState("");
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const verifyToken = searchParams.get("verifyToken");

  useEffect(() => {
    setLoading(true);
    fetch(`${servicesBase()}auth/verify_email?email=${email}&verify_token=${verifyToken}`)
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setToken(response.token);
        } else {
          setError(response.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message())
      })
  }, [])

  const success = (
    <>
      <p>Thanks for verifying your email!  You now have full access to the site.</p>
      <Button variant="contained" mt={5} onClick={() => navigate("/")}>Continue to Site</Button>
    </>
  );

  return (
    <>
      { error ? <span style={{color: 'red'}}>Error: {error}</span> : null }
      <Box margin={10}>
        <TailSpin
          height="80"
          width="80"
          color="#1976D2"
          ariaLabel="tail-spin-loading"
          radius="5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={loading}
        />
      </Box>
      { !loading ? success : null}
    </>
  )
}

export default VerifyEmail;