import React from 'react';
import {FormContainer, TextFieldElement} from "react-hook-form-mui";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


const SendInvites = () => {
  return (
    <Box m={3}>
      <FormContainer>
        <Typography variant="h4">Send Invites</Typography>
        <Grid container spacing={2} my={1}>
          <Grid item xs={12}>
            <TextFieldElement name="name" placeholder="Name" required fullWidth={true}/>
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement name="email" placeholder="Email" required fullWidth={true}/>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button variant="contained" fullWidth>Send</Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </FormContainer>
    </Box>
  );
}

export default SendInvites;