import React from 'react';
import Grid from "@mui/material/Grid";
import CustomizeAppearance from "./CustomizeAppearance";
import DeleteTeam from "./DeleteTeam";
import PendingRequests from "./PendingRequests";
import SendInvites from "./SendInvites";
import TeamMembers from "./TeamMembers";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";


const TeamAdmin = ({team, setTeam, token}) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const error = Boolean(errorMessage);
  const [infoMessage, setInfoMessage] = React.useState('');
  const info = Boolean(infoMessage);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PendingRequests team={team} token={token}/>
        </Grid>
        <Grid item xs={12}>
          <TeamMembers team={team} token={token} setErrorMessage={setErrorMessage} setInfoMessage={setInfoMessage}/>
        </Grid>
        <Grid item xs={6}>
          <CustomizeAppearance team={team} token={token}/>
        </Grid>
        <Grid item xs={6}>
          <SendInvites/>
        </Grid>
        <Grid item xs={6}>
          <DeleteTeam team={team} token={token}/>
        </Grid>
      </Grid>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{width: '100%'}}>{errorMessage}</Alert>
      </Snackbar>
      <Snackbar open={info} message={infoMessage} severity="info" onClose={() => setInfoMessage(null)}
                autoHideDuration={6000}/>
    </>
  );
}

export default TeamAdmin;
