import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {parseJwt, servicesBase, useCookieState} from "./common";
import AddHighlightsReel from './highlightsreel/AddHighlightsReel';
import AddTeam from "./addteam/AddTeam";
import Admin from './admin/Admin';
import AuthError from './AuthError';
import ChangePassword from "./ChangePassword";
import Container from "@mui/material/Container";
import EditReel from './highlightsreel/EditReel';
import EditUser from './EditUser';
import EditVideo from './EditVideo';
import FindTeam from "./FindTeam";
import Footer from "./Footer";
import ForgotPassword from "./ForgotPassword";
import HighlightsReels from './highlightsreel/HighlightsReels';
import Home from './Home';
import io from "socket.io-client";
import JoinTeam from "./JoinTeam";
import Landing from './landing/Landing';
import LoginForm from './LoginForm.js';
import NotFound from "./NotFound";
import Register from './Register.js';
import TeamAdmin from "./teamadmin/TeamAdmin";
import TopNav from './TopNav.js';
import VerifyEmail from "./VerifyEmail";
import Unsubscribe from "./Unsubscribe";


const TeamTemplate = ({showLogin, setShowLogin, team, token, setToken, isAdmin}) => {
  let theme;

  if (team && team.primary_color !== 'primary' && team.secondary_color !== 'secondary') {
    theme = createTheme({
      palette: {
        primary: {
          main: team.primary_color
        },
        secondary: {
          main: team.secondary_color
        }
      }
    })
  } else {
    theme = createTheme({})
  }



  return (
    <ThemeProvider theme={theme}>
      <TopNav team={team} setShowLogin={setShowLogin} token={token} setToken={setToken} isAdmin={isAdmin}/>
      <LoginForm show={showLogin} setShow={setShowLogin} setToken={setToken}/>
      <Container maxWidth="xl">
        <Outlet/>
      </Container>
      <Footer/>
    </ThemeProvider>
  )
}


const App = () => {
  const [showLogin, setShowLogin] = React.useState(false);
  const [token, setToken] = useCookieState('token', '');
  const admin_test = /\badmin\b/;
  let parsedToken = parseJwt(token);
  const isAdmin = admin_test.test(parsedToken?.roles);
  const [live, setLive] = React.useState(null);
  const [team, setTeam] = React.useState(null);
  const [socket, setSocket] = React.useState(null);
  const RootPage = token ? Home : Landing;
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const subdomain_tester = /([a-z0-9]+)\.baseballpeople\.(com|dev)/i;
    const subdomain = subdomain_tester.exec(window.location.host)
    const user = parseJwt(token);

    if (subdomain) {
      fetch(`${servicesBase()}teams/slug/${subdomain[1]}?user_id=${user.userId}`,
        {headers: {token: token}})
        .then((response) => {
          if (response.status === 404) {
            navigate('/not_found');
          } else if (response.status === 401) {
            setShowLogin(true);
          } else if (response.status === 200) {
            response.json().then((team) => {
              setTeam(team);
              if (location.pathname === '/not_found') {
                navigate('/');
              }
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);


  React.useEffect(() => {
    if (token) {
      const s = io(process.env.REACT_APP_SOCKET_IO_BASE, {
        transports: ['websocket'],
        auth: {
          token: token
        }
      });

      setSocket(s);
    } else {
      setSocket(null);
    }
  }, [token]);


  return (
      <Routes>
        <Route path="/landing" element={<Landing/>}/>
        <Route path="/" element={<TeamTemplate team={team} showLogin={showLogin} setShowLogin={setShowLogin}
                                               token={token} setToken={setToken} isAdmin={isAdmin}/>}>
          <Route path="/" element={<RootPage isAdmin={isAdmin} live={live} setLive={setLive} team={team} token={token}
                                             socket={socket}/>}/>
          <Route path="add_highlights_reel" element={<AddHighlightsReel token={token}/>}/>
          <Route path="add_team" element={<AddTeam token={token} team={team}/>}/>
          <Route path="admin" element={<Admin isAdmin={isAdmin} token={token} live={live}/>}/>
          <Route path="auth_error" element={<AuthError/>}/>
          <Route path="change_password" element={<ChangePassword/>}/>
          <Route path="edit_reel/:id" element={<EditReel token={token}/>}/>
          <Route path="edit_user/:id" element={<EditUser token={token}/>}/>
          <Route path="edit_video/:id" element={<EditVideo isAdmin={isAdmin} token={token}/>}/>
          <Route path="find_team" element={<FindTeam token={token}/>}/>
          <Route path="forgot_password" element={<ForgotPassword/>}/>
          <Route path="highlights_reels" element={<HighlightsReels token={token} socket={socket}/>}/>
          <Route path="join_team" element={<JoinTeam token={token} setShowLogin={setShowLogin}/>}/>
          <Route path="not_found" element={<NotFound/>}/>
          <Route path="register" element={<Register setToken={setToken}/>}/>
          <Route path="team_admin" element={<TeamAdmin team={team} setTeam={setTeam} token={token}/>}/>
          <Route path="unsubscribe" element={<Unsubscribe/>}/>
          <Route path="verify_email" element={<VerifyEmail setToken={setToken}/>}/>
        </Route>
      </Routes>
  )
}


export default App;
