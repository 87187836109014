import React from 'react';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const sectionItems = [
  {
    id: 1,
    icon: <OndemandVideoIcon sx={{fontSize: '100px'}}/>,
    sentence:
      "BaseballPeople allows you to capture livestreams from the game and broadcast them through your team's secure portal.  No internet?  Upload your games once you're back at home."
  },
  {
    id: 2,
    icon: <FolderSpecialIcon sx={{fontSize: '100px'}}/>,
    sentence:
      "BaseballPeople's intuitive interface will help you capture highlights from the game to share with the team and fans.  You can even compile highlights into a highlights reel."
  },
  {
    id: 3,
    icon: <MobileScreenShareIcon sx={{fontSize: '100px'}}/>,
    sentence:
      'The BaseballPeople mobile app will let you record videostreams from the game with the least amount of effort possible.'
  }
]


const Section = () => {
  return (
    <Box sx={{ flexGrow: 1, minHeight: '400px' }}>
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', minHeight: '400px'}}>
        {sectionItems.map((item) => (
          <Grid item
                key={item.id}
                xs={12}
                md={3.5}
                minHeight={300}
                sx={{backgroundColor: '#f2f0f1', textAlign: 'center', padding: '30px', width: '200px', borderRadius: '10px', margin: '10px !important'}}>
            {item.icon}
            <Typography>{item.sentence}</Typography>
          </Grid>
          ))}
      </Grid>
    </Box>
  )
}


export default Section;