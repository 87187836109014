import {parseJwt, servicesBase} from "./common";
import {useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import React from 'react';
import Typography from "@mui/material/Typography";


const JoinTeam = ({ token, setShowLogin }) => {
  const [message, setMessage] = React.useState("Pending");
  const [queryParams] = useSearchParams();
  const teamId = queryParams.get("team_id");
  const requestId = queryParams.get("request_id");

  React.useEffect(() => {
    const user = parseJwt(token);
    if (user) {
      fetch(`${servicesBase()}teams/${teamId}/accept_request/${requestId}?user_id=${user.userId}`,
        {method: "PUT", headers: {token: token}})
        .then((response) => {
          if (response.status === 404) {
            setMessage("Team or request not found");
          } else if (response.status === 401) {
            setMessage("You are not authorized accept this user into the team.");
          } else if (response.status === 200) {
            setMessage("Request accepted");
          }
        })
        .catch(() => setMessage("Error accepting request"));
    } else {
      setShowLogin(true);
    }
  }, [token]);

  return (
    <Grid container maxWidth={500}>
      <Grid item xs={2}>
        <Typography variant="h6" component="h6" sx={{lineHeight:'100px', height:'100px'}}>Status:</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1" sx={{lineHeight:'100px', height:'100px'}}>{message}</Typography>
      </Grid>
    </Grid>
  );
}

export default JoinTeam;