import * as React from "react";
import {parseJwt, servicesBase} from "../common";
import Grid from "@mui/material/Grid";
import ConfirmDialog from "../ConfirmDialog";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import humanizeDuration from 'humanize-duration';

dayjs.extend(RelativeTime);

const Video = ({selectVideo, video, isAdmin, handleUpdate, token}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const user = parseJwt(token);

  const selectMe = (e) => {
    e.preventDefault();
    selectVideo(video);
  };

  const videoAdmin = () => {
    return isAdmin || user.userId === video.user_id;
  }

  const deleteMe = () => {
    fetch(`${servicesBase()}videos/${video.id}?user_id=${user.userId}`, {method: 'DELETE', headers: {token: token}})
      .then(() => {
        handleUpdate();
      });
  }

  const thumbnailBase = video.is_cloud ? process.env.REACT_APP_SPACES_BASE : process.env.REACT_APP_ARCHIVE_BASE_URL;
  const downloadUrl = video.is_cloud ? `${process.env.REACT_APP_VIDEO_PROXY_BASE}${video.filename}` : thumbnailBase + video.filename;
  const videoDateTime = dayjs(video.created).fromNow();

  let videoLength = video.length.substring(0, 2) * 3600;
  videoLength += video.length.substring(3, 5) * 60;
  videoLength += video.length.substring(6, 8) * 1;

  videoLength = humanizeDuration(videoLength * 1000, {round: true});

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} role="listitem">
      <ConfirmDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        action={deleteMe}
        message={"Are you sure you want to delete this video?"}/>
      <Paper elevation={5}>
        <Grid container spacing={0}>
          <Grid item xs={12} onClick={selectMe}>
            <img
              src={thumbnailBase + video.thumbnail}
              alt="Video Thumbnail"
              style={{width: '100%', height: 'auto'}}
              onClick={selectMe}/>
          </Grid>
          <Grid item xs={11} onClick={selectMe}>
            <Grid container marginX={2}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" onClick={selectMe}>{video.title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{fontSize: "12pt"}}>{videoDateTime}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{fontSize: "12pt"}}>{videoLength}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sx={{textAlign: 'right'}}>
            <Stack spacing={0} mr={2}>
              {videoAdmin() ? <a href={`/edit_video/${video.id}`}><EditIcon sx={{color: 'black', fontsize: 20}}/></a> : null}
              <a href={downloadUrl} download><DownloadIcon sx={{color: 'black', fontSize: 20}}/></a>
              {videoAdmin() ?
                <a onClick={() => setDialogOpen(true)}><DeleteIcon sx={{color: 'black', fontSize: 20}}/></a> : null}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Video;