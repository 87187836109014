import './Home.css';
import * as React from 'react';
import {parseJwt, servicesBase, useStorageState} from "./common";
import {useReducer} from 'react';
import {useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import VideoArchive from './videoarchive/VideoArchive';
import VideoInfo from "./videoinfo/VideoInfo";
import VideoPlayer from './VideoPlayer';


const API_ENDPOINT = servicesBase();


const SearchBar = ({search, onSearch}) => {
  return (
    <Box my={2}>
      <TextField name="search" label="Search Video Names..." size="small" role="searchbox" defaultValue={search} onChange={onSearch}/>
    </Box>
  );
}


const Home = ({isAdmin, live, setLive, socket, team, token}) => {
  const [searchTerm, setSearchTerm] = useStorageState('searchTerm', '');
  const [videos, setVideos] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState({});
  const [updateNumber, handleUpdate] = useReducer(x => x + 1, 0);
  const playerRef = React.useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setIsLoading(true);
    const user = parseJwt(token);
    let url = `${API_ENDPOINT}videos?user_id=${user.userId}`;
    if (team) {
      url += `&team_id=${team.id}`;
    }

    fetch(url, {'headers': {"token": token}})
      .then(response => response.json())
      .then((result) => {
        setVideos(result);

        if (searchParams.has('videoId')) {
          const selected = result.find((video) =>
            video.id === searchParams.get('videoId'));
          setSelectedVideo(selected);
        }

        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [updateNumber, team, token]);

  React.useEffect(() => {
    const user = parseJwt(token);
    var url = "";
    if (team) {
      url = `${API_ENDPOINT}streaming/live/team/${team.id}`;
    } else {
      url = `${API_ENDPOINT}streaming/live/user/${user.userId}`;
    }

    fetch(url)
      .then(response => response.json())
      .then((response) => {
        if (response.live) {
          setLive(response)
        } else {
          setLive(null);
        }
      })
      .catch(() => {
        setLive(null)
      });
  }, [team]);

  React.useEffect(() => {
    if (socket) {
      socket.on('publish', (data) => {
        setLive(data);
      });
      socket.on('publish_done', () => {
        setLive(null);
        if (selectedVideo === "live") {
          setSelectedVideo({});
        }
      });
      socket.on('video_added', () => {
        handleUpdate();
      });
      socket.on('video_changed', () => {
        handleUpdate();
      })
      return () => {
        socket.off('publish');
        socket.off('publish_done');
        socket.off('video_added');
        socket.off('video_changed');
      }
    }
  }, [socket, selectedVideo]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  return (
    <div className="Home">
      <div className="Body">
        <VideoPlayer selectedVideo={selectedVideo} playerRef={playerRef} team={team} token={token}/>
        <VideoInfo selectedVideo={selectedVideo} token={token} playerRef={playerRef} live={live}/>
        <SearchBar search={searchTerm} onSearch={handleSearchChange}/>
        <VideoArchive
          selectVideo={setSelectedVideo}
          videos={videos.filter((x) => x.title.toLowerCase().includes(searchTerm.toLowerCase()))}
          live={live}
          isLoading={isLoading}
          isError={isError}
          isAdmin={isAdmin}
          handleUpdate={handleUpdate}
          token={token}
        />
      </div>
    </div>
  );
}

export default Home;
