import {Link} from "react-router-dom";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import React from 'react';
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import {servicesBase} from "../common";


const Teams = ({token}) => {
  const [teams, setTeams] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const error = Boolean(errorMessage);

  React.useEffect(() => {
    fetch(`${servicesBase()}teams`, {headers: {token: token}})
      .then((response) => response.json())
      .then((response) => {
        setTeams(response);
      })
      .catch((e) => {
        setTeams(null);
        setErrorMessage(e.toString());
      });
  })

  const handleDelete = (teamId) => {
    fetch(`${servicesBase()}teams/${teamId}`,
      {method: "DELETE", headers: {token: token}})
      .then(() => {
        setTeams((teams) => teams.filter((team) => team.id !== teamId));
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      });
  }

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h4">Teams</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Team Name</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Slug</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <TableRow key={team.id}>
                <TableCell>{team.name}</TableCell>
                <TableCell>{team.location}</TableCell>
                <TableCell>{team.slug}</TableCell>
                <TableCell>
                  <Link to={`/edit_team/${team.id}`}><EditIcon/></Link>
                  <DeleteIcon onClick={() => handleDelete(team.id)}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{width: '100%'}}>{errorMessage}</Alert>
      </Snackbar>
    </Grid>
  )
}

export default Teams;