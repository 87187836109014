import * as React from 'react';
import { useForm } from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {servicesBase} from "./common";

const API_ENDPOINT = servicesBase() +"auth/";

const LoginForm = ({show, setShow, setToken}) => {
  const [ error, setError ] = React.useState("");
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (inputData) => {
    const data = new FormData();
    data.append("email", inputData.email);
    data.append("password", inputData.password);
    data.append("remember", inputData.remember);

    fetch(`${API_ENDPOINT}login`, { method: "POST", body: data })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setError("");
          setShow(false);
          setToken(response.token);
        } else {
          setError("Invalid username or password");
        }})
      .catch(() => {
        setError("Error communicating with server")
      })
  }

  const forgotPassword = () => {
    navigate('/forgot_password');
    setShow(false);
  }

  return (
    <Container>
      <CssBaseline/>
      <Dialog open={show} onClose={() => setShow(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Log in</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please log in to continue
            { error ? <Typography sx={{color: 'red'}}>{error}</Typography> : null }
          </DialogContentText>
          <TextField
            {...register("email")}
            autoFocus
            required
            fullWidth
            autoComplete="email"
            margin="normal"
            label="Email Address"
            type="email"
            data-cy="login-email"
          />
          <TextField
            {...register("password")}
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            data-cy="login-password"
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => forgotPassword()}>Forgot Password</Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" data-cy="login-submit" fullWidth>Submit</Button>
        </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default LoginForm;
