import React from 'react'
import {parseJwt, secondsToTime, servicesBase} from "../common";
import {useNavigate} from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";


const HighlightsReelInstructions = ({selected}) => {
  return (
    <Box mb={2}>
      <Typography>Click on all the highlights that you'd like to include in your highlights reel.</Typography>
      { selected.length > 1 ? <Typography>Drag and drop highlights to reorder them</Typography> : null }
    </Box>
  )
}

const CreationError = ({ error }) => {
  return (
    <Typography>{error}</Typography>
  )
}

const Highlight = ({ row, selected, setSelected }) => {
  const selectMe = (e) => {
    e.preventDefault();

    setSelected((selected) => {
      if (selected.find((h) => h.highlight.id === row.highlight.id) !== undefined) {
        return selected.filter((h) => h.highlight.id !== row.highlight.id)
      } else {
        return [...selected, row];
      }
    })
  }

  const bgColor = selected.find((el) => {
    return (el.highlight.id === row.highlight.id)
  }) ? "lightblue" : "white";

  return (
    <Paper elevation={5} sx={{backgroundColor: bgColor}} onClick={selectMe}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <img
            src={`${process.env.REACT_APP_SPACES_BASE}highlights/${row.highlight.id}.png`}
            alt="Highlight thumbnail"
            style={{width: '100%', height: 'auto'}}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5">{row.highlight.title}</Typography>
          <Typography sx={{fontSize: "12pt"}}>{row.video.title}</Typography>
          <Typography sx={{fontSize: "12pt"}}>{secondsToTime(row.highlight.start_time)} - {secondsToTime(row.highlight.end_time)}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}


const SelectedHighlights = ({ selected, setSelected, token, setError }) => {
  const navigate = useNavigate();

  const createReel = (e) => {
    e.preventDefault();
    const hlCsv = selected.map((h) => h.highlight.id).join(",");

    const formData = new FormData();
    formData.append("highlights", hlCsv);
    formData.append("user_id", parseJwt(token).userId);

    fetch(`${servicesBase()}highlights/reel`, {
      method: "POST", body: formData, headers: {token: token}})
      .then((response) => response.json())
      .then((response) => {
        navigate("/highlights_reels");
      })
      .catch((error) => setError(error));
  }

  const removeHighlight = (highlight) => {
    setSelected((selected) => {
      return selected.filter((item) => {
        return item.highlight.id !== highlight.highlight.id
      });
    })
  }

  const reorder = (ev, index) => {
    ev.preventDefault();
    const newIndex = parseInt(ev.dataTransfer.getData("index"));
    const newSelected = [...selected];
    const temp = newSelected[index];
    newSelected[index] = newSelected[newIndex];
    newSelected[newIndex] = temp;
    setSelected(newSelected);
  }

  return (
    <Container>
      <Grid container>
        {selected.map((highlight, index) => (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3} sx={{position: 'relative', textAlign: 'center'}}>
              <Stack direction={"row"} sx={{position: 'relative'}}>
                <Box sx={{width: '192px'}}>
                  <IconButton size="small" onClick={() => removeHighlight(highlight)} sx={{backgroundColor: 'primary.main', color: 'white', position: 'absolute', top: 0, right: 100, zIndex: 10}}>
                    <ClearIcon/>
                  </IconButton>
                  <img
                    alt="Highlight preview gif"
                    draggable={true}
                    onDragStart={(ev) => ev.dataTransfer.setData("index", index)}
                    onDragOver={(ev) => ev.preventDefault()}
                    onDrop={(ev) => reorder(ev, index)}
                    src={`${process.env.REACT_APP_SPACES_BASE}highlights/${highlight.highlight.id}.gif`}
                    style={{border: '1px solid black', width: '192px'}}
                  />
                  <Typography>{highlight.highlight.title}</Typography>
                  <Typography>{highlight.video.title}</Typography>
                  <Typography>{secondsToTime(highlight.highlight.start_time)} - {secondsToTime(highlight.highlight.end_time)}</Typography>
                </Box>
                <Box sx={{position: 'absolute', left: '192px', top: '24px'}}>
                  <ArrowForwardIcon sx={{fontSize: "50pt"}}/><br/>
                </Box>
              </Stack>
            </Grid>
          </>
        ))}
        <Grid item xs={12} my={3}>
          <Container>
            { selected.length > 0 ? (
              <Button
                variant="contained"
                onClick={createReel}
                sx={{margin: '0 auto', display: 'block'}}
              >
                Create Highlight Video
              </Button>) : null }
          </Container>
        </Grid>
      </Grid>
    </Container>
  )
}


const HighlightsList = ({ highlights, selected, setSelected }) => {
  return (
    <Grid container spacing={2}>
      {highlights.map((highlight) => (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Highlight row={highlight} selected={selected} setSelected={setSelected}/>
        </Grid>
      ))}
    </Grid>
  )
}

const AddHighlightsReel = ({ token }) => {
  const [error, setError] = React.useState(null);
  const [highlights, setHighlights] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    if (!token) return;
    const user = token ? parseJwt(token) : {};

    fetch(`${servicesBase()}highlights/?user_id=${user.userId}`, {headers: { token: token}})
      .then((response) => response.json())
      .then((json) => setHighlights(json))
      .catch((error) => console.log(error));
  }, [token]);

  return (
    <div>
      <h1>Highlights Reel</h1>
      <HighlightsReelInstructions selected={selected}/>
      <CreationError error={error}/>
      <SelectedHighlights selected={selected} token={token} setError={setError} setSelected={setSelected}/>
      <HighlightsList highlights={highlights} selected={selected} setSelected={setSelected}/>
    </div>
  )
}

export default AddHighlightsReel;