import React from 'react';
import {parseJwt, servicesBase} from "../common";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";


const PendingRequests = ({ team, token }) => {
  const user = parseJwt(token);
  const [requests, setRequests] = React.useState([]);

  const acceptIntoTeam = (requestId) => {
    fetch(`${servicesBase()}teams/${team.id}/accept_request/${requestId}?user_id=${user.userId}`,
      { method: 'PUT', headers: { "token": token } })
      .then(response => response.json())
      .then((result) => {
        setRequests(requests.filter((request) => request.id !== requestId));
      })
      .catch((error) => console.log(error.toString()));
  }

  const declineRequest = (requestId) => {
    fetch(`${servicesBase()}teams/${team.id}/decline_request/${requestId}?user_id=${user.userId}`,
      { method: 'DELETE', headers: { "token": token } })
      .then(response => response.json())
      .then((result) => {
        setRequests(requests.filter((request) => request.id !== requestId));
      })
      .catch((error) => console.log(error.toString()));
  };

  React.useEffect(() => {
    if (team) {
      fetch(`${servicesBase()}teams/${team.id}/pending_requests?user_id=${user.userId}`,
        { headers: { "token": token } })
        .then(response => response.json())
        .then((result) => {
          setRequests(result);
        })
        .catch((error) => console.log(error.toString()));
    }
  }, [team]);

  return (
    <Box m={3}>
      <Typography variant="h4">Pending Requests</Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={3}><Typography variant="h6">Name</Typography></Grid>
        <Grid item xs={4}><Typography variant="h6">Email</Typography></Grid>
        <Grid item xs={3}><Typography variant="h6">Date</Typography></Grid>
        <Grid item xs={2}><Typography variant="h6">Actions</Typography></Grid>
        {requests.map((request) =>
          <React.Fragment key={request.id}>
            <Grid item xs={3}>{request.name}</Grid>
            <Grid item xs={4}>{request.email}</Grid>
            <Grid item xs={3}>{dayjs(request.request_date).fromNow()}</Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Box>
                <Button onClick={() => acceptIntoTeam(request.id)}>Accept</Button>
                <Button onClick={() => declineRequest(request.id)}>Decline</Button>
              </Box>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Box>
  );
}

export default PendingRequests;
