import * as React from 'react';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

const MessageDialog = ({ message, open, setOpen, action }) => {

  const close = () => {
    action();
    setOpen(false);
  }

  return (
    <Dialog onClose={action} open={open}>
      <DialogTitle>{message}</DialogTitle>
      <Container>
        <Stack direction={"row"} spacing={2} marginY={2} justifyContent="center">
          <Button variant="contained" data-cy="confirm-ok" onClick={() => close()}>Ok</Button>
        </Stack>
      </Container>
    </Dialog>
  )
}

export default MessageDialog;

