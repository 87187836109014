import * as React from 'react';
import bleachers from './bleachers.jpg';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


const NotFound = () => {
  return (
    <>
      <Box>
        <Box sx={{ position: 'absolute', color: 'white', backgroundColor: 'black', top: 100, left: 100, zIndex: 5 }}>
          <Typography variant="h1" component="h1">Oh no!</Typography>
          <Typography>There's nothing here</Typography>
          <Typography>Please try again</Typography>
        </Box>
        <img
          alt="Empty Bleachers"
          src={bleachers}
          style={{ width: '100%'}}/>
      </Box>
    </>
  );
}

export default NotFound;