import * as React from 'react';
import {FormContainer, TextFieldElement} from "react-hook-form-mui";
import {useForm} from "react-hook-form";
import {parseJwt, servicesBase} from "../common";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";


const AddTeam = ({token}) => {
  const [slug, setSlug] = React.useState("");
  const formContext = useForm();
  const [slugChangedManually, setSlugChangedManually] = React.useState(false);
  const navigate = useNavigate();
  const [ errorMessage, setErrorMessage ] = React.useState('');
  const error = Boolean(errorMessage);

  const onSubmit = (inputData) => {
    const user = parseJwt(token);
    const data = new FormData();
    data.append("name", inputData.name);
    data.append("location", inputData.location);
    data.append("slug", inputData.slug);
    data.append("user_id", user.userId);

    fetch(`${servicesBase()}teams`,
      {method: "POST", body: data, headers: {token: token}})
      .then((response) => {
        if (response.status === 201) {
          if (window.location.hostname.endsWith(".dev")) {
            window.location = `https://${inputData.slug}.baseballpeople.dev:3000`;
          } else {
            window.location = `https://${inputData.slug}.baseballpeople.com`;
          }
        } else if (response.status === 409) {
          setErrorMessage("Please choose a different abbreviation");
        } else if (response.status === 500) {
          setErrorMessage("There was a problem creating the team. Please try again later.");
        }
      })
      .catch((error) => { setErrorMessage(error.toString()) });
  }

  const nameChange = (e) => {
    const name = e.currentTarget.value;
    const names = name.split(/[ ,]+/).filter((n) => n !== "");
    const abbreviation = names.reduce((acc, curr) => acc + curr[0].toLowerCase(), "");

    if (!slugChangedManually) {
      formContext.setValue('slug', abbreviation);
      setSlug(abbreviation);
    }
  }

  const slugChange = (e) => {
    setSlug(e.currentTarget.value);
    setSlugChangedManually(true);
  }

  return (
    <Container sx={{margin: 5}}>
      <Typography variant="h5" mb={2}>Add Team</Typography>
      <FormContainer formContext={formContext} onSuccess={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldElement name="name" label="Name" onChange={nameChange} data-cy="add-team-name" fullWidth required={true}/>
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement name="location" label="Location" data-cy="add-team-location" fullWidth required={true}/>
          </Grid>
          <Grid item xs={2}>
            <TextFieldElement name="slug" label="Abbreviation" data-cy="add-team-abbreviation" onChange={slugChange} fullWidth required={true}/>
          </Grid>
          <Grid item xs={10} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Typography sx={{}}>https://{slug}.baseballpeople.com</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button type="submit" variant="contained" fullWidth>Submit</Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </FormContainer>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default AddTeam;