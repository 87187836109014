import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Hero from './Hero';
import Section from './Section';
import Comparison from './Comparison';

const Landing = () => {
  return (
    <div>
      <CssBaseline/>
      <Hero/>
      <Section/>
      <Comparison/>
    </div>
  )
}

export default Landing;