import * as React from 'react';
import VideoJS from './VideoJS';
import './VideoPlayer.css';
import {parseJwt, servicesBase} from "./common";


const VideoPlayer = ({ selectedVideo, playerRef, team, token }) => {
  const [ sources, setSources ] = React.useState([]);
  const user = parseJwt(token);

  React.useEffect(() => {
    if (selectedVideo === "live") {
      let arg = "";
      if (team) {
        arg=`?team_id=${team.id}`;
      } else {
        arg=`?user_id=${user.userId}`;
      }

      fetch(`${servicesBase()}streaming/sources${arg}`)
        .then(response => response.json())
        .then((result) => {
          setSources(result);
        });
    }
  }, [selectedVideo]);

  if (selectedVideo?.filename || selectedVideo === "live") {

    const videoJsOptions={
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 2, 3, 5, 10],
      preload: 'auto',
    }

    if (selectedVideo === "live") {
      videoJsOptions.sources = sources;
      videoJsOptions.liveui = true;
    } else {
      const videoBase = selectedVideo.is_cloud ? process.env.REACT_APP_SPACES_BASE : process.env.REACT_APP_ARCHIVE_BASE_URL;
      videoJsOptions.sources = [
        {src: `${videoBase}${selectedVideo.filename}`, type: 'video/mp4'}
      ];
    }

    const handlePlayerReady = (player) => {
      playerRef.current = player;
    }

    return (
      <>
        <div className="videoplayer">
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} className="vjs-16-9"/>
        </div>
      </>
    );
  } else {
    return (
      <div className="blankVideo">
        <span>Select a video below</span>
      </div>
    );
  }
}


export default VideoPlayer;
