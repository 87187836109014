import React from 'react';
import {CookiesProvider} from "react-cookie";
import {BrowserRouter} from "react-router-dom";
import App from "./App";

const Root = () => {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default Root;