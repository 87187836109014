import React from 'react';
import {parseJwt, servicesBase} from "../common";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RedoIcon from '@mui/icons-material/Redo';
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";


const TeamMembers = ({team, token, setErrorMessage, setInfoMessage}) => {
  const [teamMembers, setTeamMembers] = React.useState([]);
  const user = parseJwt(token);

  React.useEffect(() => {
    if (!team) {
      return;
    }

    fetch(`${servicesBase()}teams/${team.id}/members?user_id=${user.userId}`, {headers: {token: token}})
      .then(response => response.json())
      .then(result => {
        setTeamMembers(result);
      })
      .catch((e) => setErrorMessage(e.toString()));

  }, [team]);

  const resendAcceptEmail = (userId) => {
    fetch(`${servicesBase()}teams/resend_accept_email?user_id=${user.userId}&resend_user_id=${userId}&team_id=${team.id}`,
      {method: "PUT", headers: {token: token}})
      .then(() => {
        setInfoMessage("Email resent");
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      });
  }

  return (
    <Box m={3}>
      <Typography variant="h4">Team Members</Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={4}>Name</Grid>
        <Grid item xs={4}>Email</Grid>
        <Grid item xs={2}>Role</Grid>
        <Grid item xs={2}>Actions</Grid>
        {teamMembers.map((member) =>
          <React.Fragment key={member.id}>
            <Grid item xs={4}>{member.name}</Grid>
            <Grid item xs={4}>{member.email}</Grid>
            <Grid item xs={2}>{member.roles}</Grid>
            <Grid item xs={2}>
              <Tooltip title="Resend Accept Email">
                <RedoIcon onClick={() => resendAcceptEmail(member.id)}/>
              </Tooltip>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Box>
  );
}

export default TeamMembers;