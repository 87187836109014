import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useForm} from "react-hook-form";
import Stack from "@mui/material/Stack";
import {parseJwt, servicesBase} from "./common";



const EditVideo = ({ isAdmin, token }) => {
  const [ video, setVideo ] = React.useState(null);
  const [ error, setError ] = React.useState("");
  const navigate = useNavigate();
  let { id } = useParams();
  let { handleSubmit, register, reset } = useForm({
    defaultValues: {}
  });


  React.useEffect( () => {
    const user = parseJwt(token)
    fetch(`${servicesBase()}videos/${id}?user_id=${user.userId}`, { headers: { token: token }})
      .then((response) => response.json())
      .then((response) => {
        setVideo(response)
        if (!isAdmin && response.user_id !== user.userId) {
          navigate("/auth_error");
        }
      })
      .catch(() => {
        setError("Unable to load video");
        setVideo(null);
      });
  }, []);

  React.useEffect(() => {
    reset(video);
  }, [video])

  const onSubmit = (inputData) => {
    const user = parseJwt(token);
    const data = new FormData();
    data.append("user_id", user.userId);
    data.append("thumbnail", inputData.thumbnail);
    data.append("filename", inputData.filename);
    data.append("title", inputData.title);
    data.append("length", inputData.length);
    data.append("is_cloud", inputData.is_cloud ? "1" : "0");

    fetch(`${servicesBase()}videos/${id}`, { method: "PUT", body: data, headers: { token: token } })
      .then((response) => {
        if (response.status === 200) {
          navigate("/");
        }
      })
      .catch((e) => {
        setError(e.toString())
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    { error ? <Typography sx={{color: 'red'}}>{error}</Typography> : null}
    <Container sx={{ mt: 10 }}>
      <CssBaseline/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography>Id</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            {...register('id', { required: true})}
            disabled={true}
            fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>Thumbnail</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            {...register('thumbnail', { required: true})}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>Filename</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            {...register('filename', { required: true})}
            fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>Title</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            {...register("title", { required: true})}
            fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>Length</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            {...register('length', { required: true})}
            fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>Stored in Cloud</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            {...register('is_cloud', {required:true})}
            fullWidth/>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} margin={2} spacing={2}>
            <Button type="submit" variant="contained" fullWidth>Save Changes</Button>
            <Button variant="contained" fullWidth onClick={() => navigate('/')}>Cancel</Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
    </form>
  );
}

export default EditVideo;