import React, {useEffect} from 'react';
import {parseJwt, secondsToTime, servicesBase} from '../common';
import ConfirmDialog from "../ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import Grid from "@mui/material/Grid";
import HoldToCaptureButton from "./HoldToCaptureButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


const VideoInfo = ({ selectedVideo, token, playerRef, live }) => {
  return (
    <Grid container>
      <Grid item xs={12} med={10}>
        <Typography component="h4" variant="h4">{selectedVideo.title}</Typography>
      </Grid>
      <Grid item xs={12} med={2}>
        {selectedVideo?.id || selectedVideo === 'live' ? <HighlightsList selectedVideo={selectedVideo} token={token} playerRef={playerRef} live={live}/> : null}
      </Grid>
    </Grid>
  );
}


const HighlightsList = ({selectedVideo, playerRef, token, live}) => {
  const [highlights, setHighlights] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  useEffect(() => {
    if (!selectedVideo) {
      return;
    }

    const tokenFields = parseJwt(token);
    let url;
    if (selectedVideo === 'live') {
      url = `${servicesBase()}highlights/live?live_id=${live.id}&user_id=${tokenFields.userId}`;
    } else {
      url = `${servicesBase()}highlights/${selectedVideo.id}?user_id=${tokenFields.userId}`;
    }

    setIsLoading(true);
    fetch(url,
      { headers: { 'token': token }})
      .then(response => response.json())
      .then((result) => {
        setHighlights(result);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [selectedVideo, token]);

  const navigateToHighlight = (highlight) => {
    playerRef.current.currentTime(highlight.start_time);
  }

  const [ buttonAttributes, setButtonAttributes ] = React.useState({color: "primary", name: "Capture Highlight"});
  const [ start, setStart ] = React.useState(0);

  const startCapture = () => {
    setButtonAttributes({color: "secondary", name: "Capturing..."});
    if (selectedVideo === 'live') {
      fetch(`${servicesBase()}highlights/live_time?live_id=${live.id}`)
        .then(response => response.json())
        .then((result) => {
          setStart(result);
        });
    } else {
      setStart(playerRef.current.currentTime());
    }
  }

  const storeHighlight = (start, end) => {
    const tokenFields = parseJwt(token);
    const form = new FormData();
    form.append('user_id', tokenFields.userId);
    form.append('title', 'Untitled Highlight');
    form.append('start_time', start);
    form.append('end_time', end);

    if (selectedVideo === 'live') {
      form.append("live_id", live.id);
    }

    const videoId = selectedVideo === 'live' ? 'live' : selectedVideo.id;

    fetch(`${servicesBase()}highlights/${videoId}`,
      { method: 'POST', body: form, headers: {'token': token} })
      .then(response => response.json())
      .then((result) => {
        setHighlights([...highlights, result]);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const endCapture = () => {
    setButtonAttributes({color: "primary", name: "Capture Highlight"});

    if (selectedVideo === 'live') {
      fetch(`${servicesBase()}highlights/live_time`)
        .then(response => response.json())
        .then((result) => {
          storeHighlight(start, result);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      storeHighlight(start, playerRef.current.currentTime());
    }

  }

  return (
    <Grid container>
    {highlights.map(highlight => <Highlight key={highlight.id} highlight={highlight} navigateToHighlight={navigateToHighlight} token={token} setHighlights={setHighlights}/>)}
    <Grid item xs={12}>
      <HoldToCaptureButton bodyColor={buttonAttributes.color} startEvent={startCapture} endEvent={endCapture}>
        {buttonAttributes.name}
      </HoldToCaptureButton>
    </Grid>
     </Grid>
  );
}


const Highlight = ({ highlight, navigateToHighlight, setHighlights, token }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState(highlight.title);

  const go= (e) => {
    e.preventDefault();
    navigateToHighlight(highlight);
  }

  const deleteMe = () => {
    fetch(`${servicesBase()}highlights/${highlight.id}`, { method: 'DELETE', headers: {'token': token} })
      .then((result) => {
        if (result.status === 204) {
          setHighlights((highlights) => highlights.filter(h => h.id !== highlight.id));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const saveTitle = () => {
    const data = new FormData();
    data.append('user_id', highlight.user_id);
    data.append('title', title);
    data.append('start_time', highlight.start_time);
    data.append('end_time', highlight.end_time);

    fetch(`${servicesBase()}highlights/${highlight.video_id}/${highlight.id}`, { method: 'PUT',
      body: data, headers: {'token': token} })
      .catch((e) => { console.log(e); });

    setEditing(false);
  }

  return (
    <>
      <ConfirmDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        action={deleteMe}
        message={"Are you sure you want to delete this highlight?"}/>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          <a href="#" onClick={go}><Typography>{secondsToTime(highlight.start_time)} - {secondsToTime(highlight.end_time)}</Typography></a>
          { editing ? <input type="text" onKeyDown={(ev) => ev.key === "Enter" && saveTitle()} onChange={(ev) => setTitle(ev.currentTarget.value)} value={title}/>: <Typography>{title}</Typography> }
          <Box>
            { editing ? <button onClick={saveTitle}>save</button>: <a onClick={() => setEditing(true)}><EditIcon sx={{color: 'black', fontSize: 20}}/></a> }
            <a onClick={() => setDialogOpen(true)}><DeleteIcon sx={{color: 'black', fontSize: 20}}/></a>
          </Box>
        </Stack>
      </Grid>
    </>
  );
}

export default VideoInfo;