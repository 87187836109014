import React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";


const Comparison = () => {
  return (
    <Box mx={5} minHeight="300px">
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={6}>
          <Paper elevation={5} sx={{height:350}}>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{backgroundColor:'#086100', color: 'white'}}>
                <Typography component="h1" variant="h5" m={1}>Team Admins can...</Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  <ListItem>Brand your team's site with team colors and logos</ListItem>
                  <ListItem>Restrict who can view your team's site, or keep it open to the public</ListItem>
                  <ListItem>Provide a place to share videos with other parents and fans</ListItem>
                  <ListItem>Promote your team on social media using highlight reels</ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{}}>
          <Paper elevation={5} sx={{height:350}}>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{backgroundColor:'#BF6000', color: 'white'}}>
                <Typography component="h1" variant="h5" m={1}>Fans can...</Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  <ListItem>Watch live or recorded games from anywhere.</ListItem>
                  <ListItem>Easily share videos with parents, grandparents, and fans</ListItem>
                  <ListItem>Keep track of your best plays across multiple games and teams</ListItem>
                  <ListItem>Assemble highlight reels to share with college coaches using our easy tools</ListItem>
                  <ListItem>Keep your videos private, share them with the team, or share them with the world</ListItem>
                  <ListItem>Capture live videos of your team using your phone or tablet using our iOS and Android Applications</ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}


export default Comparison;