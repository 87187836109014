import {parseJwt, servicesBase} from "../common";
import {useForm} from "react-hook-form";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import React from 'react';
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


const CustomizeAppearance = ({team, token}) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const error = Boolean(errorMessage);
  const [infoMessage, setInfoMessage] = React.useState("");
  const info = Boolean(infoMessage);

  const { handleSubmit, register } = useForm({
    defaultValues: team
  });

  const onSubmit = (data) => {
    const user = parseJwt(token);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("location", data.location);
    formData.append("primary_color", data.primary_color);
    formData.append("secondary_color", data.secondary_color);
    formData.append("logo", data?.logo[0]);
    formData.append("has_logo", Boolean(data.logo));
    formData.append("user_id", user.userId);

    fetch(`${servicesBase()}teams/${team.id}`,
      { method: "PUT", body: formData, headers: { token: token } })
      .then((response) => {
        if (response.status === 200) {
          setInfoMessage("Successfully Updated.");
        } else {
          setErrorMessage("Error updating appearance.");
        }
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      })
  }

  return (
    <Box m={3}>
      <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
        <Typography variant="h4">Customize Look and Feel</Typography>
        <Grid container spacing={2} my={1}>
          <Grid item xs={12}>
            <TextField
              {...register("name", { required: true })}
              label="Team Name"
              fullWidth={true}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("location", { required: true })}
              label="Location"
              fullWidth={true}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("primary_color", { required: true })}
              label="Primary Color"
              fullWidth={true}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("secondary_color", { required: true })}
              label="Secondary Color"
              fullWidth={true}/>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Select Logo
              <input hidden accept="image/*" name="logo" type="file" {...register("logo")}/>
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button variant="contained" type="submit" fullWidth>Save</Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </form>
      <Snackbar
        open={error||info}
        autoHideDuration={6000}
        onClose={() => {
          setErrorMessage('');
          setInfoMessage('');
        }}>
        <Alert onClose={() => {
          setErrorMessage('');
          setInfoMessage('');
        }}
          severity={ error ? "error" : "info" }
          sx={{ width: '100%' }}
        >{ error ? errorMessage : infoMessage }</Alert>
      </Snackbar>
    </Box>
  )
}

export default CustomizeAppearance;