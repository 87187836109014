import React from 'react';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormContainer, PasswordElement, PasswordRepeatElement} from "react-hook-form-mui";
import {servicesBase} from "./common";

const ChangePassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const onSubmit = (formData) => {
    const data = new FormData();
    data.append("password", formData.password);
    data.append('token', token);

    fetch(`${servicesBase()}auth/change_password`, {method: "POST", body: data})
      .then((response) => response.json())
      .then((response) => {
        navigate("/");
      });
  }

  return (
    <Container m={3}>
      <FormContainer onSuccess={onSubmit}>
        <Grid container spacing={3} my={1} maxWidth="500px">
          <Grid item xs={12}>
            <Typography variant="h4">Change Password</Typography>
            <Typography variant="body1">Enter your new password below.</Typography>
          </Grid>
          <Grid item xs={12}>
            <PasswordElement
              name="password"
              autoFocus
              size="small"
              label="New Password"
              required
              autoComplete="new-password"
              fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <PasswordRepeatElement
              name="verify_password"
              passwordFieldName="password"
              size="small"
              label="Enter New Password Again"
              required
              autoComplete="new-password"
              fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">Change</Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Container>
  );
}

export default ChangePassword;