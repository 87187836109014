import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {baseDomain, parseJwt, servicesBase} from './common';
import baseball from './baseball.png';
import Alert from "@mui/material/Alert";
import AppBar from '@mui/material/AppBar';
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Snackbar from "@mui/material/Snackbar";
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from "@mui/material/Typography";

const UserComponents = ({ team, token, setToken, setShowLogin, isAdmin }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const user = token ? parseJwt(token) : {};
  const [openAddVideo, setOpenAddVideo] = React.useState(false);
  const [anchorAddVideo, setAnchorAddVideo] = React.useState(null);
  const [keyExists, setKeyExists] = React.useState(false);
  const [streamKey, setStreamKey] = React.useState('');
  const [teamMenuEl, setTeamMenuEl] = React.useState(null);
  const [isTeamAdmin, setTeamAdmin] = React.useState(false);
  const showTeamMenu = Boolean(teamMenuEl);
  const [errorMessage, setErrorMessage] = React.useState('');
  const error = Boolean(errorMessage);
  const [teams, setTeams] = React.useState([]);

  React.useEffect(() => {
    if (team) {
      fetch(`${servicesBase()}teams/${team.id}/is_admin?user_id=${user.userId}`,
        { headers: { "token": token } })
        .then(response => response.json())
        .then((result) => {
          setTeamAdmin(result.is_admin);
        })
        .catch((error) => setErrorMessage(error.toString()));
    }
  }, [team])

  React.useEffect(() => {
    if (user.userId) {
      fetch(`${servicesBase()}teams/user/${user.userId}`, { headers: { token: token } })
        .then(response => response.json())
        .then((result) => {
          setTeams(result);
        });
    }
  }, [team, token])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
    setOpenAddVideo(false);
  }
  const handleLogout = () => {
    setAnchorEl(null);
    setToken('');
    window.location = `https://${baseDomain()}`;
  }

  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/edit_user/" + user.userId);
  }

  const handleHighlights = () => {
    setAnchorEl(null);
    navigate("/highlights_reels");
  }

  function streamVideoClick(event) {
    fetch(`${servicesBase()}streaming/key_exists?user_id=${user.userId}`,
      { headers: { "token": token } })
      .then(response => response.json())
      .then((result) => {
        setKeyExists(result.exists);
      });

    setAnchorAddVideo(event.currentTarget);
    setOpenAddVideo((av) => !av);
  }

  function generateKey() {
    const data = new FormData();
    data.append("user_id", user.userId);
    fetch(`${servicesBase()}streaming/generate_key`,
      { method: 'POST', body: data, headers: { "token": token } })
      .then(response => response.json())
      .then((result) => {
        setKeyExists(true);
        setStreamKey(result.stream_key);
      });
  }

  function teamsClick(event) {
    setTeamMenuEl(event.currentTarget);
  }

  function teamsClose() {
    setTeamMenuEl(null);
  }

  function teamsCloseAndNavigate(url) {
    setTeamMenuEl(null);
    navigate(url);
  }

  function navigateToTeam(slug) {
    setTeamMenuEl(null);
    window.location = "https://" + slug + "." + baseDomain();
  }

  if (token) {
    return (
      <>
        <Stack direction={"row"} spacing={2}>
          <Button color="inherit" data-cy="Teams" onClick={teamsClick}>Teams</Button>
          <Menu
            id={"team-menu"}
            open={showTeamMenu}
            anchorEl={teamMenuEl}
            onClose={teamsClose}
            data-cy="team-menu"
          >
            {isTeamAdmin ? <MenuItem onClick={() => teamsCloseAndNavigate("/team_admin")}>Team Admin</MenuItem> : null}
            {isTeamAdmin ? <Divider /> : null}
            {teams.map((t) => <MenuItem key={t.id} onClick={() => navigateToTeam(t.slug)}>{t.name}</MenuItem>)}
            {teams.length > 0 ? <Divider /> : null}
            <MenuItem data-cy="add-team-menu" onClick={() => teamsCloseAndNavigate("/add_team")}>Create Team</MenuItem>
            <MenuItem data-cy="find-team-menu" onClick={() => teamsCloseAndNavigate("/find_team")}>Find My Team</MenuItem>
          </Menu>
          <Button color="inherit" data-cy="StreamVideo" onClick={streamVideoClick}>Add Video</Button>
          <Popover
            id="addvideopopup"
            open={openAddVideo}
            anchorEl={anchorAddVideo}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            style={{ width: "100%" }}
          >
            <Box m={3}>
              <Typography variant="h6">Add Video by Streaming</Typography>
              <Typography variant="body1">
                To stream video to BaseballPeople, you will need to use an app that supports RTMP
                streaming.  Examples include Mevo, Larix, OBS Studio, and the BaseballPeople app.
              </Typography>
              {keyExists ? (
                <>
                  <Grid container my={3} sx={{ 'width': '100%' }}>
                    <Grid item xs={4} sx={{ 'fontWeight': 'bold' }}>RTMP Server URL</Grid>
                    <Grid item xs={8}>rtmp://baseballpeople.com/live</Grid>
                    <Grid item xs={4} sx={{ 'fontWeight': 'bold' }}>Stream Key</Grid>
                    <Grid item xs={8}>{streamKey ? (
                      <Typography data-cy="StreamKey" sx={{ fontFace: 'Courier' }}>{streamKey}</Typography>) : "Your streaming key"}</Grid>
                  </Grid>
                  <Typography>
                    {streamKey ? "Save this key!  It will disappear once you leave this page!" : "If you have lost your streaming key, generate a new one by clicking this button:"}
                  </Typography>
                  {!streamKey ? (
                    <Container>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        data-cy="GenerateKey"
                        onClick={() => generateKey()}>Generate Key</Button>
                    </Container>
                  ) : null}
                </>
              ) : (
                <>
                  <Typography variant="body1" mb={3}>Click here to generate a streaming key:</Typography>
                  <Container>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: '20px', marginBottom: '20px' }}
                      data-cy="GenerateKey"
                      onClick={() => generateKey()}>Generate Key</Button>
                  </Container>
                </>
              )}
            </Box>

          </Popover>
          <Avatar onClick={handleClick}>{user.name[0]}</Avatar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          >
            {isAdmin ? <MenuItem onClick={() => {
              handleClose();
              navigate("/admin");
            }}>Admin</MenuItem> : null}
            <MenuItem onClick={handleHighlights}>Highlights Reel</MenuItem>
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Stack>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}>
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      <>
        <Button color="inherit" data-cy="Login" onClick={() => {
          setShowLogin(true)
        }}>Login</Button>
        <Link to="/register"><Button color="inherit" data-cy="Register">Register</Button></Link>
      </>
    );
  }
}

const TopNav = ({ setShowLogin, team, token, setToken, isAdmin }) => {
  let logoFile = baseball;
  if (team && team.has_logo) {
    logoFile = `${process.env.REACT_APP_SPACES_BASE}${team.logo_path}`;
  }

  let title = "BaseballPeople";
  if (team) {
    title = team.name;
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/"><img src={logoFile} alt="Logo" style={{height:"100%", maxHeight:"100px"}}/></Link>
          <Typography
            variant="h5"
            component="div"
            noWrap
            aria-label="Site Name"
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            {title}
          </Typography>
          <UserComponents team={team} setShowLogin={setShowLogin} token={token} setToken={setToken} isAdmin={isAdmin} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default TopNav;
