import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {CheckboxElement, FormContainer, TextFieldElement} from "react-hook-form-mui"
import {useForm} from "react-hook-form";
import {parseJwt, servicesBase} from "./common";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ConfirmDialog from "./ConfirmDialog";


const EditUser = ({ token }) => {
  const claims = parseJwt(token)
  const [ error, setError ] = React.useState("");
  const navigate = useNavigate();
  let { id } = useParams();
  let formContext = useForm();
  const [ confirmDelete, setConfirmDelete ] = React.useState(false);

  React.useEffect(() => {
    fetch(`${servicesBase()}auth/user/${id}`, { headers: { token: token }})
      .then((response) => response.json())
      .then((response) => {
        formContext.reset(response);
      })
      .catch((e) => {
        setError(e.toString());
      });
  }, []);

  const onSubmit = (inputData) => {
    const data = new FormData();
    data.append("id", inputData.id);
    data.append("email", inputData.email);
    data.append("name", inputData.name);
    data.append("roles", inputData.roles);
    data.append("notify_by_email", inputData.notify_by_email ? "1" : "0");
    data.append("notify_by_sms", inputData.notify_by_sms ? "1" : "0");
    data.append("phone_number", inputData.phone_number);
    data.append("verified", inputData.verified ? "1" : "0");

    fetch(`${servicesBase()}auth/user/${id}`, { method: "PUT", body: data, headers: { token: token } })
      .then((response) => {
        if (response.status === 200) {
          navigate("/");
        }
      });
  }

  const deleteMe = () => {
    fetch(`${servicesBase()}auth/user/${id}`, { method: "DELETE", headers: { token: token } })
      .then((response) => {
        if (response.status === 204) {
          navigate("/");
        }
      });
  }

  return (
    <Container sx={{margin:5}}>
      <ConfirmDialog
        action={deleteMe}
        message={"Are you sure you want to delete this user?"}
        open={confirmDelete}
        setOpen={setConfirmDelete}
      />
      <Typography component="h1" variant="h5" marginY={5}>Edit User</Typography>
      <FormContainer formContext={formContext} onSuccess={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextFieldElement name="id" label="Id" disabled={true}/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextFieldElement name="email" label="email" fullWidth/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextFieldElement name="name" label="name" fullWidth/>
          </Grid>
          { claims.roles.includes("admin") ? <Grid item xs={12} sm={12}><TextFieldElement name="roles" label="roles" fullWidth/></Grid> : null }
          <Grid item xs={12} sm={12}>
            <TextFieldElement name={"phone_number"} label={"Phone Number"} type='tel'/>
          </Grid>
          { claims.roles.includes("admin") ? <Grid item xs={12} sm={12}><TextFieldElement name={"verified"} label={"verified"}/></Grid> : null }
          <Grid item xs={12} sm={12}>
            <CheckboxElement name="notify_by_email" label="Notify me by email when new games are announced"/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CheckboxElement name="notify_by_sms" label="Notify me by SMS when new games are announced"/>
          </Grid>
          <Grid item xs={2}>
            <Button type="submit" variant="contained">Submit</Button>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={2}>
            <Button type="button" variant="contained" onClick={() => setConfirmDelete(true)}>Delete Me</Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Container>
  );
}

export default EditUser;