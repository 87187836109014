import React from 'react';
import {FormContainer} from "react-hook-form-mui";
import {parseJwt, servicesBase} from "../common";
import {useNavigate} from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ConfirmDialog from "../ConfirmDialog";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";


const DeleteTeam = ({ team, token }) => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const error = Boolean(errorMessage);

  const deleteMe = () => {
    const user = parseJwt(token);
    fetch(`${servicesBase()}teams/${team.id}?user_id=${user.userId}`, { method: "DELETE", headers: { token: token } })
      .then((response) => {
        if (response.status === 204) {
          if (window.location.hostname.endsWith(".dev")) {
            window.location = "https://baseballpeople.dev:3000";
          } else {
            window.location = "https://baseballpeople.com";
          }
        }
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      });
  }

  return (
    <Box m={3}>
      <ConfirmDialog
        action={deleteMe}
        message={"Are you sure you want to delete this team?"}
        open={confirmDelete}
        setOpen={setConfirmDelete}
      />
      <FormContainer>
        <Typography variant="h4">Delete Team</Typography>
        <Typography>
          Deleting a team is a permanent action.  There is no coming back and all videos will be deleted.
        </Typography>
        <Grid container spacing={2} my={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button variant="contained" data-cy="delete-team" onClick={() => setConfirmDelete(true)} fullWidth>Delete Team</Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </FormContainer>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}>
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
      </Snackbar>
    </Box>
  );
}

export default DeleteTeam;